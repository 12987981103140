<template>
  <div class="sidebar-nav" v-bind:class="{'open': isMobileMenuOpen}">
    <button class="mobile-button" @click="toggleMenu()">
      <span class="icon-ec icon-bars"></span>
    </button>
    <router-link id="epicentric-logo" class="epicentric-logo" to="/"> 
      <img class="logo-desk" alt="Epicentric logo" src="../assets/logo/epicentric-technology-logo-dark.png">
      <img class="logo-mobile" alt="Epicentric logo" src="../assets/logo/logo_mobile.svg">
    </router-link>

    <div id="menu-wrap" class="menu-wrap">
      <ol type="1">
        <li><p><router-link to="/epicentric-technology">Fundamentals</router-link></p>
          <ol type="1">
            <li><p><router-link to="/epicentric-technology/your-catalog">Your catalog</router-link></p></li>
            <li><p><router-link to="/epicentric-technology/epikeys">Epikeys</router-link></p></li>
            <li><p><router-link to="/epicentric-technology/links">Links</router-link></p></li>
            <li><p><router-link to="/epicentric-technology/maps">Maps</router-link></p></li>
            <li><p><router-link to="/epicentric-technology/your-digital-universe">Your digital universe</router-link></p></li>
          </ol>
        </li>
        <li><p><router-link to="/access-and-play-your-content">Access and play your Content</router-link></p>
          <ol type="1">
            <li><p><router-link to="/access-and-play-your-content/from-your-catalog">From your catalog</router-link></p></li>
            <li><p><router-link to="/access-and-play-your-content/through-your-links">Through Your Links</router-link></p></li>
            <li><p><router-link to="/access-and-play-your-content/generating-playlists">Generating playlists</router-link></p></li>
          </ol>
        </li>
        <li><p><router-link to="/playlist-engine">Playlist Engine</router-link></p>
          <ol type="1">
            <li><p><router-link to="/playlist-engine/intelligent-random">Intelligent Random</router-link></p></li>
            <li class="no-margin-b"><p><router-link to="/playlist-engine/single-playlist-mode">Single Playlist mode</router-link></p>
              <ol type="1">
                <li><p><router-link to="/playlist-engine/full-random">Full Random (default)</router-link></p></li>
                <li><p><router-link to="/playlist-engine/one-epikey-at-time">One Epikey at a time</router-link></p></li>
              </ol>
            </li>
            <li class="no-margin-b"><p><router-link to="/playlist-engine/playlist-path-mode">Playlist Path mode</router-link></p>
              <ol type="1">
                <li><p><router-link to="/playlist-engine/playlist-path-mode/loop-manual-skip">Loop - Manual skip</router-link></p></li>
                <li><p><router-link to="/playlist-engine/playlist-path-mode/loop-timed-skip">Loop - Timed skip</router-link></p></li>
                <li><p><router-link to="/playlist-engine/playlist-path-mode/audio-driven-skip">Audio-Driven skip</router-link></p></li>
              </ol>
            </li>
            <li><p><router-link to="/playlist-engine/ignore-ordered-sequences">Ignore ordered Sequences</router-link></p></li>
          </ol>
        </li>
        <li><p><router-link to="/the-multilevel-customization">The Multilevel Customization</router-link></p>
          <ol type="1">
            <li><p><router-link to="/the-multilevel-customization/customize-at-content-level">Customize at Content Level</router-link></p></li>
            <li><p><router-link to="/the-multilevel-customization/customize-at-link-level">Customize at Link Level</router-link></p></li>
            <li><p><router-link to="/the-multilevel-customization/customize-at-epikey-level">Customize at Epikey/Map Level</router-link></p></li>
            <li><p><router-link to="/the-multilevel-customization/conflicts-between-parameters">Conflicts between parameters</router-link></p></li>
          </ol>
        </li>
      </ol>
    </div>

    <div class="cta-wrap">
      <!-- <a class="cta-btn beta-signup" href="https://play01.epicentric.world/signup">
        Sign up for BETA
      </a> -->
      <a class="cta-btn signup" href="https://play01.epicentric.world/signup">
        Sign up for BETA
      </a>
      <a class="cta-btn login" href="https://play01.epicentric.world/">
        Log in
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarNav',
  data () {
    return {
      isMobileMenuOpen: false
    }
  },
  watch: {
    $route () {
      var elements = document.querySelectorAll('.page')
      elements.forEach(function (item) {
        item.scrollTo(0,0)
      })
      this.isMobileMenuOpen = false
    }
  },
  methods: {
    toggleMenu () {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sidebar-nav {
  position: relative;
  z-index: 999999999;
  height: 100%;
  width: 300px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-color: $BG_W;
  border-right: 1px solid $ORANGE;

  .mobile-button{
    display: none;
    position: absolute;
    top: 0px;
    left: 100%;
    height: 57px;
    width: 57px;
    background-color: $BG_W;
    border: 0px;
    border-left: 1px solid $ORANGE;
    border-right: 1px solid $ORANGE;
    border-bottom: 1px solid $ORANGE;
    .icon-ec{
      font-size: 1.5rem;
      color: $TEXT_B;
    }
  }

  .epicentric-logo {
    position: relative;
    width: 100%;
    height: 60px;
    opacity: 1;
    transition: all .4s ease-out;
    text-align: center;
    &.hide {
      opacity: 0;
    }
    img {
      height: 100%;
      margin: 0 auto;
      &.logo-desk {
        display: block;
      }
      &.logo-mobile {
        display: none;
      }
    }
  }

  .menu-wrap {
    width: 100%;
    height: calc(100% - 140px);
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto;
    
    a {
      display: block;
      width: 100%;
      color: #333333;
      font-size: .9rem;
      transition: all .3s ease-in;
      text-decoration: none;
      font-weight: bold;
      &:hover {
        color: darken($ORANGE, 0%);
      }
      &.router-link-active {
        // border-bottom: 1px solid $ORANGE;
        color: $ORANGE;
        &:hover {
          color: $ORANGE;
        }
      }
    }
    
    ol{
      display: block;
      list-style-type: decimal;
      margin-top: .5em;
      margin-bottom: .5em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
      li{
        display: list-item;
        color: $TEXT_B;
        p{
          color: $TEXT_B;
          cursor: pointer;
          transition: all .3s ease-out;
          &.selected{
            color: $TEXT_W;
            background-color: $ORANGE;
            &:hover{
              color: $TEXT_W;
            }
          }
          &.sub-cat{
            background-color: $LIGHT_GREY;
            &:hover{
              color: $TEXT_B;
            }
          }
          &:hover{
            color: $ORANGE;
          }
        }
      }
    }
    ol {
      list-style-type: none;
      counter-reset: item;
      margin: 0;
      padding: 0;
      // padding-bottom: 14px;
    }

    ol > li {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
      // padding-top: 10px;
      &.no-margin-b{
        margin-bottom: 0em;
      }
    }

    ol > li:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.6em;    
    }

    li ol > li {
      margin: 0;
    }

    li ol > li:before {
      content: counters(item, ".") " ";
    }
  }
  
  .cta-wrap{
    height: 60px;
    width: 100%;
    .cta-btn {
      display: inline-block;
      width: 70px;
      padding: 5px 0px;
      text-align: center;
      text-decoration: none;
      font-size: .9rem;
      transition: all .3s ease-out;
      &.beta-signup{
        width: calc(100% - 70px);
        border: 1px solid $GREY;
        background-color: $GREY;
        color: $TEXT_B;
        margin-bottom: 7px;
        &:hover {
          color: $TEXT_B;
          background-color: $GREY;
        }
      }
      &.signup {
        width: 100%;
        border: 1px solid $GREEN;
        background-color: $GREEN;
        color: $TEXT_W;
        margin-bottom: 7px;
        &:hover {
          color: $GREEN;
          background-color: $BG_W;
        }
      }
      &.login {
        width: 100%;
        border: 1px solid $ORANGE;
        background-color: $ORANGE;
        color: $TEXT_W;
        &:hover {
          color: $ORANGE;
          background-color: $BG_W;
        }
      }
    } 
  }
}
 
@media (max-width: 1023px) {
  .sidebar-nav {
    position: absolute;
    top: 0px;
    left: -305px;
    z-index: 9999999999;
    transition: all .4s ease-out;
    .mobile-button{
      display: block;
    }
    &.open{
      left: 0px;
    }
  }
}

@media (max-width: 1024px) {
  header {
    .menu-wrap{
      a {
        height: 45px;
        line-height: 45px;
      }
      .has-submenu{
        .sub-menu{
          margin-left: 5px;
          display: block;
          border-left: 1px solid #EA8B21;
        }   
      }
    }
  }
}

@media (max-width: 550px) {
  header {
    .cta-wrap{
      .cta-btn{
        &.login{
          display: none;
        }
      } 
    }
  }
}

@media (max-width: 374px) {
  header {
    .epicentric-logo{
      img{
        &.logo-desk {
          display: none;
        }
        &.logo-mobile {
          display: block;
        }
      }
    }
  }
}
</style>
