export default {
  en: {
    BetaText: 'Sign up for BETA',
    BetaTextAlt: '<span class="bold">If this is you, start here</span>. Sign up for BETA',
    GalleryBack: '< Back to Map Gallery',
    SignupBtnLabel: 'Sign up',
    LoginBtnLabel: 'Log in',
    PrivacyLabel: 'Privacy Policy',
    TermsOfUseLabel: 'Terms of use',
    // Galleries
    CtaGalleryTitle: 'Check out a few examples of what you can do!',
    CtaGalleryDetailTitle: 'Check out other Maps in this category',
    GalleryTitle: 'Maps Gallery',
    GalleryText: 'Check out our Map templates and get inspired!',
    // Home page
    HomeClaim: 'OVERCOME DIGITAL CHAOS<br><span>and create your own digital Universe!</span>',
    HomeClaimLink: 'What do we mean by Digital Chaos',
    HomeSacredTitle: 'YOUR SACRED SPACE',
    HomeSacredText: 'Your <span class="bold">private, one-and-only</span> place<br>to enhance and care for your collection',
    HomeMultimediaTitle: 'YOUR MULTIMEDIA STATION',
    HomeMultimediaText: 'A powerful tool to <span class="bold">get the most out</span> of your digital content',
    HomeMultimediaSubTitle: 'The very heart of the Epicentric® revolution',
    HomeMultimediaSubText: ' A Human-Centric Technology',
    HomeConnectTitle: 'Connect to yourself',
    HomeConnectText1: '<span class="bold">Chart</span> your path of Self-discovery and creation',
    HomeConnectText2: 'Use Epicentric® to <span class="bold">consciously choose</span> and integrate<br>any and all <span class="bold">material of value</span> from our digital world',
    HomeTakeALookTitle: 'Take a look at what you could do',
    HomeTakeALookText: 'Discover your Epicentric’s <span class="bold">infinite potential</span>',
    HomeLoversTitle: 'Epicentric lovers',
    HomeLoversLink: 'Check out a brand new way of being in a digital world',
    HomeVisionTitle: 'The Epicentric® vision',
    HomeVisionText: '<span class="bold">Another way of being in a digital world</span>',
    HomeVisionLink: 'Discover our philosophy',
    // Revolutionary
    RevoTitle: 'A REVOLUTIONARY TOOL',
    RevoSubTitle: 'INTO THE VERY HEART OF EPICENTRIC®',
    RevoCentricTitle: 'A Human-Centric Technology',
    RevoText: '<span class="bold">Connect yourself to the digital</span> content that is part of your life.<br>Shape <span class="bold">your own unique universe</span>.<br>Live an <span class="bold">energizing experience</span> focused on the self, inspiring boundless imagination.',
    RevoOrganizeTitle: 'ORGANIZE YOUR UNIVERSE',
    RevoOrganizeSubTitle1: 'CREATE YOUR EPIKEYS',
    RevoOrganizeText1a: 'Epikeys are keywords you can use to <span class="bold">define</span> any <span class="bold">concept</span>.<br>They could be emotions, activities, artists, places, people, special moments in your life, and anything you want.<br><span class="bold">Without limits to your creative imagination!</span>',
    RevoOrganizeText1b: 'Then you can use Epikeys like “virtual folders” to which you can link any kind of content you associate with them.',
    RevoOrganizeText1c: 'Example: you can create the Epikey “Pure Joy” to connect music, images, videos that you relate to this emotion.',
    RevoOrganizeSubTitle2: 'CONNECT WITH YOUR INNER LINKS',
    RevoOrganizeText2a: 'Close your eyes and begin a journey deep inside yourself to discover the <span class="bold">invisible and unique</span> connections existing within yourself, between a chosen <span class="bold">concept</span> and digital <span class="bold">content</span>.',
    RevoOrganizeText2b: 'Then connect any kind of content you want to your Epikeys, thus creating various Links.Through Links creation, Epicentric® <span class="bold">learns</span> and <span class="bold">saves</span> all those connections.',
    RevoOrganizeSubTitle3: 'Unlimited choices:',
    RevoOrganizeSubTitle4: 'COMPOSE YOUR MAPS',
    RevoOrganizeText3a: '<span class="bold">Arrange your Epikeys</span> how best suits you, and access them easily.<br>Create your magical configuration!<br>Design groupings, sequences, pathways...with infinite possibilities.',
    RevoOrganizeText3b: 'What’s more, different Map structures will generate <span class="bold">different</span> <a href="#streaming-experiences" class="link">streaming experiences</a>.',
    RevoOrganizeSubTitle5: 'WHAT IT MEANS TO YOU',
    RevoOrganizeText4a: 'Fill in the description field of your Epikeys, Links, Maps to <span class="bold">capture its meaning or use</span>.',
    RevoOrganizeText4b: '“The words you attach to your experience become your experience”<br><i>Tony Robbins</i>',
    RevoOrganizeSubTitle6: 'YOUR MULTIMEDIA STATION',
    RevoOrganizeText5: 'Epicentric’s user interface is designed to <span class="bold">comprehensively manage</span> all different kinds of content <span class="bold">at once</span>.',
    RevoOrganizeText6a: 'You‘ll be able to organize, enjoy, and share music, audio, visual, and text content all together.',
    RevoOrganizeText6b: 'See some examples of what you can do by visiting our <a href="/maps-gallery" class="link">Map Gallery</a>.',
    RevoOrganizeSubTitle7: 'YOUR DIGITAL UNIVERSE',
    RevoOrganizeText7a: 'Your Epikeys, Links, and Maps turn your digital library into a <span class="bold">meaningful</span>, <span class="bold">useful</span>, and <span class="bold">personal</span> universe.',
    RevoOrganizeText7b: 'Everything here is centered on your <span class="bold">unique connection</span> with content, i.e.<br>• the <span class="bold">meaning</span> that each item of content has for you<br>• the <span class="bold">emotions</span> you associate with it<br>• the <span class="bold">uses</span> you make of it, and more!',
    RevoOrganizeText7c: 'Finally, Epicentric® is not about music, images etc - <span class="bold">It’s about YOU</span>.',
    RevoStreamTitle: 'STREAM YOUR UNIQUE EXPERIENCES',
    RevoStreamSubTitle1: 'DISCOVER THE PLAYLIST EVOLUTION',
    RevoStreamText1: 'Epicentric® transforms your digital library into your <span class="bold">personal streaming service</span>.<br>A tool set of innovative features allows you a high level of customization to <span class="bold">get exactly the experience you want</span>.',
    RevoStreamSubTitle2: 'THE PLAYLIST ENGINE',
    RevoStreamText2: '<span class="bold">Playlist Engine</span> generates <span class="bold">your own multimedia playlists</span> from your <span class="bold">Maps</span> with just one click.',
    RevoStreamSubTitle3: 'INTELIGENT RANDOM®',
    RevoStreamText3: '<span class="bold">Intelligent Random®</span> and its related features (Frequency, Play First, Freeze, Chain Links) offer you <span class="bold">maximum variety</span> and a <span class="bold">high level of customization</span>.',
    RevoStreamSubTitle4: 'MULTILEVEL CUSTOMIZATION',
    RevoStreamText4: '<span class="bold">Customize the playback</span> of your content, without duplicating or modifying the original files, on 3 different levels: Content, Link, and Epikey.',
    RevoStreamSubTitle5: 'YOUR MULTIMEDIA JOURNEYS',
    RevoStreamText5: '<p class="mb-short">Epicentric’s Multimedia Playlists are especially <span class="bold">unique</span>.</p><p>One of the advantages of having mapped out all your Audio and Visual content, is that now you can generate and enjoy <span class="bold">Audio-Visual playlists</span>!</p><p class="mb-short">In fact, when you activate your Epikeys and Maps, the Playlist Engine will stream playlists made up of all their related <span class="bold">Audio and Visual content simultaneously</span>.</p><p class="mb-short">Moreover, you can describe each item of content with text or audio comments and notes.</p><p class="mb-short">For example, you can create:</p><p class="mb-short">• A photo album that plays a slideshow of the pictures you took on vacation, along with<br>your audio commentary of them.<br>• A playlist of tunes to work out to, paired with inspirational images and comments that really get you pumped!</p><p class="mb-short"><span class="bold">An Explosive Mix</span><br>Thanks to Epicentric’s Technology and multimediality you’ll be able to create <span class="bold">any kind of multimedia experience</span> for professional purposes, as well as for pleasure, such us:</p><p class="mb-short">• Albums, collections and reportage<br>• Thematic, chronological and emotional compositions<br>• Educational, learning and training pathways and routines<br>and much more...</p><p class="mb-regular txt-c">See some examples of what you can do by visiting our <a href="/maps-gallery" class="link">Map Gallery</a></p><p class="txt-orange bold mb-short txt-c">Totaly another kind of streaming</p>',
    RevoShareTitle: 'SHARE YOUR WORLD',
    RevoShareSubTitle: 'INTO THE VERY HEART OF EPICENTRIC®',
    RevoShareText1: 'What you share in Epicentric is not just your music, images, books etc, but the special <span class="bold">relationship you have with them</span>.<br>How do you use them? Why are they important to you? How do they fit into your life?<br>That’s why sharing with Epicentric® is <span class="bold">something else</span>!',
    RevoShareText2: '<span class="bold">Sharing functionality is under construction.</span><br>We are working to set up sharing spaces where you can interact and experience each other’s dimension, in a cozy, intimate environment.',
    // Epicentric & music
    MusicTitle: 'Epicentric® & Music',
    MusicSubTitle1: 'Have your music colection always with you',
    MusicText1: '<p class="mb-short">Epicentric® is specifically designed for real <a href="/epicentric-and-music/music-lovers" class="link">Music Lovers</a><br>It’s perfect for your <span class="bold">Music collection</span>.</p><p class="mb-short">Set up your <span class="bold">personal</span> streaming platform: <span class="bold">upload</span> your files (mp3, m4a, wave, flac),<br>and enjoy them <span class="bold">from any of your devices</span>.<br>You can also <span class="bold">embed or link</span> public domain content from other platforms (YouTube, Vimeo, etc.).</p><p>Are you a proud music owner?<br>Find out <a href="/epicentric-and-music/why-to-own-music" class="link">7 good reasons to own music</a></p>',
    MusicSubTitle2: 'Unleash the power of music in your life',
    MusicText2: 'Enhance, organize and get the most out of your Music Library.<br>Create your own unique Music Universe by using <a href="/revolutionary-tool" class="link">Epikeys, Links and Maps</a>',
    MusicText3: 'Achieve the listening experience you want by using <span class="bold">a tool set of innovative features</span>.<br>Generate your <span class="bold">truly personal</span> playlists with just one click.',
    MusicText4: 'Discover the <a href="/revolutionary-tool#streaming-experiences" class="link">Playlist Evolution</a>',
    MusicSubTitle3: 'Take advantage of infinite possibilities',
    MusicText5: '<p class="mb-short">Include Visual and Audio content to your playlists!<br>Moreover, you can describe each item of content with text <span class="bold">comments and notes</span>.</p><p class="mb-short">Use Epicentric® to create your truly personal <span class="bold">routines, musical journeys, educational material</span>, presentations and much more, as well as a lot of other fun stuff for pleasure and recreation.</p><p>Open up your experience to <span class="bold">infinite possibilities</span> by setting up your <a href="/revolutionary-tool#multimedia-journeys" class="link">Multimedia Journeys</a></p>',
    // Epicentric Why Own music
    WhyOwnMusicTitle: 'Why to own music',
    WhyOwnMusicSubTitle1: '7 good reasons for a passionate choice. Choose to own your music!',
    WhyOwnMusicStepTitle1: 'The music you buy is yours and yours only',
    WhyOwnMusicStepText1: 'Buying music and creating a collection that you own guarantees you the pleasure, the freedom and the right to live out your passion to the fullest.',
    WhyOwnMusicStepTitle2: 'What you buy lasts for ever',
    WhyOwnMusicStepText2: 'The music you buy becomes your property for as long as you wish: you and you alone decide on your collections, selections or playlists.<br>It doesn’t work that way, however, when you rely on a streaming platform: suddenly you find that some tunes.',
    WhyOwnMusicStepTitle3: 'Collect all the audio you want – the sky’s the limit',
    WhyOwnMusicStepText3a: 'With your music, you can freely “compose” an entire customised universe of songs and sounds, without limitations, including for example:',
    WhyOwnMusicStepText3b: '• Tracks and compositions of musicians and labels not available in the catalogs of streaming platforms;<br>• Rare recordings of great artists;<br>• Performances of local street artists, bands and orchestras;<br>• Your own creations, such as covers or originals recorded by you or your band;<br>• Different kinds of audio tracks: sounds, speech, talks, or any sound effects you may<br>',
    WhyOwnMusicStepTitle4: 'Support your favourite artists in a concrete and direct matter',
    WhyOwnMusicStepText4: 'Purchasing their music is the best way to thank, support and help promote the artists you love most.<br>Most of all, the up-and-coming and little-known acts who don’t get much visibility and cannot survive on the meagre royalties they get from streaming:<br>on a platform, the artist must garner a huge number of listens to guarantee them a sufficient income to pursue their career.',
    WhyOwnMusicStepTitle5: 'Use your favourite tools and do it your way',
    WhyOwnMusicStepText5a: 'The ability to enjoy your own music without restrictions is priceless: listen, edit, organize and share it using your chosen technology, software and systems, with all the features and quality you desire.',
    WhyOwnMusicStepText5b: 'No constraints or “closed circuit” systems imposed on you by others.',
    WhyOwnMusicStepTitle6: 'The buyer’s rights',
    WhyOwnMusicStepText6a: 'When you purchase music – in whatever format, analog or digital – you acquire full and exclusive rights to use and enjoy it as you please. You can also lend, exchange, donate and resell your musical collection.',
    WhyOwnMusicStepText6b: 'Furthermore, you can lawfully:',
    WhyOwnMusicStepText6c: '• make copies for private use;<br>• reproduce mixes, mashups and personally edited tunes in a private setting.',
    WhyOwnMusicStepText6d: 'Careful! When buying music online you might come across “fake property rights” i.e. the mere concession of a life-long license of use, just as, for example, when purchasing music in digital format from Amazon or iTunes.',
    WhyOwnMusicStepTitle7: 'Your collection will always be of value',
    WhyOwnMusicStepText7: 'Whether large or small, your music collection also has long-lasting monetary value that can grow with time.',
    // Music lovers
    MusicLoversTitle: 'Music Lovers',
    MusicLoversSubTitle1: 'TRULY PASSIONATE ABOUT MUSIC, NOT JUST LISTENERS',
    MusicLoversSubTitle2: 'FALL IN LOVE WITH YOUR COLLECTION',
    MusicLoversText1: 'To <span class="bold">own and care for your music collection</span> means to choose passion and freedom.<br>Whether they’re vinyl records, cds or in other digital formats, if you have your own collection, you have the advantage of being able to <span class="bold">listen to exactly what you want - how, when and wherever you like</span>.<br>Epicentric® is the platform specifically designed for the <a class="link" href="/epicentric-and-music/why-to-own-music">music that you own</a>. ',
    MusicLoversSubTitle3: 'LIVING THE ENERGY OF MUSIC TO THE MAX',
    MusicLoversText2: '<span class="bold">Music has a huge impact on our lives</span>. It touches us deeply, generates different emotions and states of mind, and enlivens significant memories, occasions and events. It expresses that which we are and what we experience.<br>A real Music Lover gets the most out of music’s magnificent power.<br>Epicentric® has been created precisely for this! ',
    MusicLoversSubTitle4: 'EXPRESS YOUR UNIQUENESS',
    MusicLoversText3: 'If you enjoy <span class="bold">choosing, discovering and listening to your music in a personalized way</span>, you’re looking for something other than the experience offered by the usual streaming platforms.<br>Rankings, trends, prompts and mass playlists generated by centralized algorhythms just don’t do it for you.<br>In Epicentric® only you shall create your ideal <a class="link" href="/revolutionary-tool">musical universe</a>.',
    MusicLoversSubTitle5: 'RELATING TO OTHERS',
    MusicLoversText4: 'Connect to others and feel as one with all: the desire to socialize is part of us.<br>Epicentric® helps make sharing a captivating, intimate experience to enable us to have authentic communication and expand our horizons. ',
    MusicLoversSubTitle6: 'GET ENTHUSED, ACTIVE AND CREATIVE',
    MusicLoversText5: '<span class="bold">Preparing your own listening experience</span>, creating compilations and playlists for both yourself and others must be one of the most satisfying activities for a Music Lover.<br>You get to work, experiment, tweak things your way, and have great fun doing it!<br>Epicentric® fires up your desire to create, and offers you a brand new way to do it:<br>discover the <a href="/revolutionary-tool#streaming-experiences" class="link">playlist evolution</a>',
    MusicLoversSubTitle7: 'SHARE AND INVOLVE',
    MusicLoversText6: '<span class="bold">Connecting with others through music</span> is a wonderful way of communicating.<br>You get in sync with people, you engage them intimately, and open up new dimensions of sound to all.<br>Circulate the energy of the music in your Epicentric® <a href="/revolutionary-tool#share-experience" class="link">Sharing Spaces</a>.',
    MusicLoversTextHighlight: 'Everything in Epicentric® is designed for Music Lovers.<br>Because it was dreamed of, thought up and created by lovers of music.',
    // Epicentric & audio
    AudioTitle: 'Epicentric® & Audio',
    AudioSubTitle1: 'KEEP YOUR AUDIO LIBRARY AT YOUR FINGERTIPS',
    AudioText1: 'Set up your <span class="bold">personal streaming</span> platform: <span class="bold">upload</span> your collection (mp3, m4a, wave, flac),and enjoy it <span class="bold">from any of your devices</span>.<br>You can also <span class="bold">embed or link</span> public domain content from other platforms (YouTube, Vimeo, etc.).',
    AudioSubTitle2: 'MAKE THE MOST OUT OF YOUR AUDIO LIBRARY',
    AudioText2: '<span class="bold">Organize</span>, classify and group your audio content according to <span class="bold">your own criteria</span> and needs.<br>See how to do it with <a href="/revolutionary-tool" class="link">Epikeys, Links and Maps</a>',
    AudioText3: '<p class="mb-short">Achieve the listening experience you want by using <span class="bold">a tool set of innovative features</span>.<br>Generate your <span class="bold">truly personal</span> playlists with just one click.</p><p class="mb-regular">Discover the <a href="/revolutionary-tool#streaming-experiences" class="link">Playlist Evolution</a>.</p>',
    AudioSubTitle3: 'TAKE ADVANTAGE OF INFINITE POSSIBILITIES',
    AudioText4: '<p class="mb-short">Include Music and Visual content to your playlists!<br>Moreover, you can describe each item of content with text <span class="bold">comments and notes</span>.</p><p class="mb-short">Use Epicentric® to create your truly personal <span class="bold">exercise routines, educational and learning material</span>, as well as for a lot of <span class="bold">other ideas</span> you may have for your inspiration and pleasure.</p><p>Open up your experience to <span class="bold">infinite possibilities</span> by setting up your <a href="/revolutionary-tool#multimedia-journeys" class="link">Multimedia Journeys</a></p>',
    // Epicentric & visual
    VisualTitle: 'Epicentric® & Visual',
    VisualSubTitle1: 'HAVE YOUR VISUAL COLECTION ALWAYS WITH YOU',
    VisualText1: '<p class="mb-short">Finally, you can have a one-and-only-place to collect  and gather all your visual content:<br><span class="bold">photos, images, videos</span> and <span class="bold">any kind of visual</span> file you may own.</p><p>You can both upload your private material and arrange public domain content (link/embed) from other platforms (YouTube, Vimeo, etc.).<br>Enjoy your visual content <span class="bold">wherever</span> you are, <span class="bold">whenever</span> you like, <span class="bold">from any of your devices</span>.</p>',
    VisualSubTitle2: 'MAKE THE MOST OUT OF YOUR VISUAL UNIVERSE',
    VisualText2: '<p class="mb-regular">Use a revolutionary system to <span class="bold">organize</span> and <span class="bold">enhance</span> your collection.<br>Arrange your visual content as desired by focusing on <span class="bold">yourself</span>: emotions, memories, projects, activities.<br>See how to do it with <a href="/revolutionary-tool" class="link">Epikeys, Links & Maps</a>.</p>',
    VisualText3: '<p class="mb-short">Design your thematic, conceptual or chronological Maps,<br>and generate your visual playlists with just one click.</p><p class="mb-regular">Discover the <a href="/revolutionary-tool#streaming-experiences" class="link">Playlist Evolution</a></p>',
    VisualSubTitle3: 'TAKE ADVANTAGE OF INFINITE POSSIBILITIES',
    VisualText4: '<p class="mb-short">Include your Music and Audio material too! Moreover, you can describe each item of content with text or audio <span class="bold">comments and notes</span>.</p><p class="mb-short">Use Epicentric® to create your truly personal <span class="bold">photo albums, travel reportage, educational & learning pathways</span>, publishing and presentation material, and for any other visual experience and ideas you may have.</p><p>Open up your experience to <span class="bold">infinite possibilities</span> by setting up your <a href="/revolutionary-tool#multimedia-journeys" class="link">Multimedia Journeys</a>.</p>',
    // Epicentric & you
    YouTitle: 'Epicentric® & You',
    YouSubTitle1: 'CONNECT YOURSELF TO THE CONTENT YOU LOVE',
    YouText1: '<p>Setup your private multimedia station and start your journey of <span class="bold">self-discovery</span> and <span class="bold">self-creation!</span><br>Use your Epicentric® to <span class="bold">empower yourself</span> as you integrate digital content into your life...</p>',
    YouSubTitle2: 'BECOME THE MASTER OF YOUR DIGITAL UNIVERSE',
    YouText2: '<p class="mb-short">Don’t be overwhelmed by the digital expanse: live <span class="bold">your own meaningful and unique universe</span>.<br>Epicentric’s <a href="/revolutionary-tool" class="link">human-centric</a> technology will let you <span class="bold">connect</span> any and all digital content with your emotions,projects, dreams, activities and everything you love and value in your life.</p><p class="mb-regular">You’ll become more aware of your deep connection to everything that matters to you, by<br>• <span class="bold">Choosing</span> the content and material that is significant to you<br>• <span class="bold">Deciding</span> how to arrange it and <span class="bold">get the most out of it</span><br>• <span class="bold">Creating</span> your <span class="bold">truly personal</span> experiences to <span class="bold">enjoy</span> and <span class="bold">share</span> as YOU like</p>',
    YouSubTitle3: 'IT’S ALL ABOUT YOU',
    YouText3: '<p>Epicentric® is designed to enable you to <span class="bold">get the most out of</span> digital content in every area of your life.<br>The heart of Epicentric® is not the content, but those who use it: <span class="bold">that’s you!</span><br>Digital content is the means, but <span class="bold">the person is the end</span>.</p>',
    // Epicentric Lovers
    LoversTitle: 'Epicentric® Lovers',
    LoversSubTitle1: 'ANOTHER WAY OF BEING IN A DIGITAL WORLD',
    LoversText1: 'If you\'re an Epicentric® Lover, you\'re...',
    LoversSubTitle2: 'ACTIVE',
    LoversText2: '<span class="bold">Be passionate about your collection of digital material</span><br>Delight in the pleasure of creating, sorting, experimenting and sharing.<br>Get busy with Epicentric® and do things your way. ',
    LoversSubTitle3: 'FREE',
    LoversText3: '<span class="bold">Live out your uniqueness</span><br>Relive the joy of truly authentic personal experiences. You won\'t find centralized algorithms pushing mass recommendations on you here. You are your Epicentric®. ',
    LoversSubTitle4: 'IN TOUCH WITH THE SELF',
    LoversText4: '<span class="bold">Choose what\'s important to you</span><br>Discover how to shape your digital environment to get the most out of your life: connect to emotions, rituals, dreams, projects and people... everything that is a part of you and your existence.<br>Epicentric® will take you on an infinite journey into the Self.',
    // Epicentric Vision
    VisionTitle: 'The Epicentric® Vision',
    VisionSubTitle1: 'THE HUMAN-CENTRIC REVOLUTION',
    VisionSubTitle2: 'IT\'S ALL ABOUT THE PERSON',
    VisionText1: 'We firmly believe in people and in technology that benefits their lives.<br><span class="bold">The heart of Epicentric®</span> is not the digital content, but those who use it: <span class="bold">that\'s you</span>, and everyone of us.<br>We\'ve created this application to satisfy deep-seated human desires that give us all something in common.',
    VisionSubTitle3: 'UNIQUENESS',
    VisionText2: 'Every person is one-of-a-kind, and wishes to discover and express his individuality.<br>Using Epicentric®, each one of us can create and arrange their <span class="bold">very own multimedia universe, different from every other</span>.<br>Epicentric is a new dimension designed to <span class="bold">celebrate your uniqueness</span>.',
    VisionSubTitle4: 'PROACTIVITY',
    VisionText3: 'The human being is both active and creative, finding fulfilment in doing and making.<br>In the digital world, just as in the physical, we need <span class="bold">tools that inspire us to create, explore and progress</span>.<br>Epicentric® was designed for this: to light up your thoughts and kick-start you into action, and build what you desire with your very own hands.',
    VisionSubTitle5: 'RELATING TO OTHERS',
    VisionText4: 'Connect to others and feel as one with all: the desire to socialize is part of us.<br>Epicentric® helps make sharing a captivating, intimate experience to enable us to have authentic communication and expand our horizons.',
    VisionSubTitle6: 'PASSION',
    VisionText5: 'We live off experiences that infuse us with energy and allow us to feed off our passions.<br>Epicentric® is a powerful and innovative platform for those who love to nurture, liberate, and live out their passion for the digital to the fullest.',
    VisionSubTitle7: 'A VISION BEYOND THE DIGITAL',
    VisionSubTitle8: 'BE THE CAPTAIN OF YOUR OWN DIGITAL DESTINY',
    VisionText6: '<span class="bold">We work with people</span> to help them improve their experience and empower themselves as they <span class="bold">integrate digital content into their lives</span>.',
    VisionSubTitle9: 'EPICENTRIC® BECOMES AN “EXTENSION” OF THE INDIVIDUAL',
    VisionText7: '<span class="bold">The Epicentric® users knowingly</span> decide what things to filter and endows them with <span class="bold">significance</span> by connecting to them and adding them to their life in whatever ways they wish.',
    VisionSubTitle10: 'AN INTERFACE BETWEEN ONE’S SELF AND THE WORLD',
    VisionText8: 'Epicentric® becomes a kind of interface that you consciously put between yourself and your enviroment.<br><span class="bold">The Epicentric® users apply their human intelligence</span>, their intention and choice in interpreting, giving meaning to, and efficiently using what they gather from “the world” in their life.',
    // ABOUT US
    AboutTitle: 'About Us',
    AboutIntroText: 'Epicentric® is developed by:',
    AboutVirtousAddress: '<p>Virtuous Circle S.a.<br>Via Gaggiolo 27 - 6855 Stabio - Switzerland<br>Founded in June 2015</p>',
    AboutSubTitle1: 'EPICENTRIC TEAM',
    AboutDescLuigi: '<p><span class="bold">Luigi Romano</span><br>CEO/Founder</p>',
    AboutDescLorenzo: '<p><span class="bold">Lorenzo Cogliati</span><br>Front-end developer</p>',
    AboutDescGian: '<p><span class="bold">Gianandrea Terzi</span><br>Solution architect</p>',
    AboutDescMilka: '<p><span class="bold">Milka Gandini</span><br>Web editor</p>',
    AboutDescDario: '<p><span class="bold">Dario Cogliati</span><br>Visual designer</p>',
    // CONTACT
    ContactTitle: 'Contact',
    ContactText: 'Write us an email at <a href"mailto:info@epicentric.world" class="link">info@epicentric.world</a>, we will reply as soon as possible.',
    // GALLERIES
    WorkoutSession: 'Workout session',
    WorkoutSessionDesc: '<p class="mb-short">Create a PLAYLIST ITINERARY for your sports and workout sessions:<br><span class="bold">each Epikey represents a stage</span> in your routine.</p><ul class="mb-short"><li>Perfect your <span class="bold">musical itinerary</span>: add or eliminate stages/Epikeys</li><li>Activate the Map and <span class="bold">at every stage</span> of your routine you\'ll be listening to <span class="bold">the “right” playlist</span>, with the most appropriate music you\'ve linked to each Epikey</li><li>Link Music to each Epikey/step</li><li>Enjoy <span class="bold">playlists</span> that are <span class="bold">always different</span>:<span class="bold"> </span>every playlist generated queues the tracks played during your last session to the end</li><li>You can <span class="bold">adjust the timeline</span> of the sequence by setting the duration of each stage/Epikey: Epicentric will run the playlists in order according to the time frames set</li><li>You can <span class="bold">mark the beginning of each stage</span> of your routine <span class="bold">with specific sounds</span> (eg. vocals or instrumental breaks, the sound of a gong...) by choosing the “Play First” option</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><span class="bold"><br></span><em>Add</em><span class="bold"><em> </em><em>AUDIO</em><em> </em></span><em>and</em><span class="bold"><em> </em><em>IMAGES</em></span><em> </em><em>that you find useful and stimulating.</em></p>',
    MyMusicalJourneys: 'My musical journeys',
    MyMusicalJourneysDesc: '<p class="mb-short">Your music in a CRONOLOGICAL ITINERARY:<br><span class="bold">each</span> numbered <span class="bold">Epikey corresponds to a musical period.</span></p><ul class="mb-short"><li>The Map proposes itineraries by genre: activate them and begin your musical journey through time with a <span class="bold">playlist for each period</span></li><li>Take a <span class="bold">different musical trip every time</span>: the playlists will queue recently played tracks last</li><li>You can <span class="bold">create a timeline</span> in the sequence by adjusting the <span class="bold">duration</span> of each musical period/Epikey: Epicentric will run the playlists in order according to the time frames set</li><li>You can mark the cronological transitions with <span class="bold">sounds or introductory audio </span>tracks by choosing the “Play First” option</li></ul><p class="mb-short"><em><span class="bold">Make it MULTIMEDIA!</span></em></p><p class="mb-regular">Add<span class="bold"> <em>AUDIO </em></span>and<span class="bold"> <em>IMAGES </em></span><em>of concerts, memorable events or magical moments in your musical life:</em><br><em>make your journey even more meaningful!</em></p>',
    MyMourningRoutine: 'My mourning routine',
    MyMourningRoutineDesc: '<p class="mb-short">Create a <span class="bold">SOUNDTRACK for each stage </span>of your morning routine:<br>from when you wake up until you step out the door.</p><ul class="mb-short"><li>Begin your day with your <span class="bold">musical itinerary</span>: each numbered Epikey is sequenced and represents part of your routine</li><li>Link Music to each Epikey/step</li><li>Activate the Map (by clicking the PLAY ICON) and <span class="bold">at every stage</span> of your routine you\'ll be listening to <span class="bold">the “right” playlist</span>, with the most appropriate music you\'ve linked to the respective Epikey</li><li>Enjoy <span class="bold">playlists</span> that are <span class="bold">always different</span>: every playlist generated queues the tracks played over the past few days to the end</li><li>You can <span class="bold">adjust the timings </span>of the sequence by setting the <span class="bold">duration</span> of each stage/Epikey: Epicentric will run the playlists in order according to the timeline set</li><li>You can <span class="bold">mark the beginning of each stage</span> of your routine <span class="bold">with specific sounds</span> (eg. vocals or instrumental breaks) by choosing the “Play First” option</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><br><em>Add </em><span class="bold"><em>AUDIO </em></span><em>and</em><span class="bold"><em> </em><em>IMAGES</em></span><em> </em><em>to the Map and enhance your experience even more!</em></p>',
    MyMusicCollection: 'My music collection',
    MyMusicCollectionDesc: '<p class="mb-short">ORGANIZE your Music by genre, <span class="bold">sub-categories, artists and albums</span><br>and enjoy INFINITE PLAYLISTS from your entire record collection!</p><ul class="mb-short"><li><span class="bold">Enhance</span> your collection with an ordered and comprehensive overview, and <span class="bold">quickly access</span> the music you want</li><li>Turn on your <span class="bold">personal “radio”</\'ll bring up a playlist with all the tracks of all your Epikeys!</li><li>Enjoy <span class="bold">playlists</span> that are <span class="bold">always different</span>: every playlist generated queues the most recently played tracks to the end</li><li>Bring up <span class="bold">playlists by genre, artist or album </span>by clicking the PLAY ICON on parts of the Map or single Epikeys</li><li>Listen to albums or tracks in a specifically <span class="bold">ordered sequence</span> by choosing the options in the Streaming Engine</li><li><span class="bold">Fine-tune</span> your listening experience to your desires with the <span class="bold">EQ settings</span> on entire branches of the Map (eg. by genre)</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><br><em>Have you got </em><em><span class="bold">photos</span></em><em> of concerts, artist or album covers?</em><br><em>Link them to your Map and you\'ll get multimedia playlists!</em></p>',
    BusinessAndTraining: 'Business and training',
    BusinessAndTrainingDesc: '<p class="mb-short">ARRANGE your <span class="bold">audiolibrary</span> to PERFECTION with your business and training materials.</p><ul class="mb-short"><li>Get a complete and ordered overview of your audio content, and <span class="bold">quickly access</span> what you need</li><li><span class="bold">Design your Map</span> according to your personal needs and criteria, branching out into themes, objectives, projects, etc.</li><li>Listen to an audio file by clicking on the Link inside the Epikey, or activate the whole Epikey to get a playlist made up of all its contents</li><li>You can add <span class="bold">memos</span>, pin <span class="bold">ideas</span> or note useful <span class="bold">information</span> in the description field in each Epikey or any of its contents</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><span class="bold"><br></span><em>Add useful </em><em><span class="bold">IMAGES</span> </em><em>or </em><em><span class="bold">MUSIC</span></em><em> to help you focus!</em></p>',
    MyGuidedSessions: 'My guided sessions',
    MyGuidedSessionsDesc: '<p class="mb-short">Gather together your guided activity sessions and create ITINERARIES in <span class="bold">steps, represented by each Epikey</span>.</p><ul class="mb-short"><li>Have your guided sessions <span class="bold">ready to listen</span> to <span class="bold">when</span> and <span class="bold">whenever you want</span></li><li>Build your <span class="bold">personal itineraries</span> to your needs: add or eliminate stages/Epikeys.</li><li>Link audio to each Epikey/step</li><li>Activate an itinerary and listen as you follow the steps in the <span class="bold">playlist</span> made up of your chosen audio files</li><li>You can <span class="bold">adjust the timeline</span> of the sequence by setting the <span class="bold">duration</span> of each stage/Epikey: Epicentric will run the playlists in order according to the time frames set</li><li>You can <span class="bold">mark and begin each stage</span> with <span class="bold">specific sounds</span> (eg. instrumental breaks, the sound of a gong...) by choosing the “Play First” option</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><span class="bold"><br></span><em>Add </em><em><span class="bold">MUSIC</span></em><em> and </em><em><span class="bold">IMAGES</span> </em><em>that you find useful and stimulating.</em></p>',
    MyAudioLibrary: 'My audio library',
    MyAudioLibraryDesc: '<p class="mb-short">GATHER your <span class="bold">audiobooks</span>, <span class="bold">readings</span> and <span class="bold">podcasts</span> together<br>and ORGANIZE your <span class="bold">virtual bookshelves</span> by genre, author, or favourites.</p><ul class="mb-short"><li>Organize your entire audiolibrary and <span class="bold">access</span> the titles you want <span class="bold">quickly and easily</span></li><li><span class="bold">Design your Map</span> according to your personal needs and criteria, by connecting either a single audiobook or a complete series to your Epikeys</li><li>Listen to an audiobook by clicking on the Link inside the Epikey, or activate the whole Epikey to get a playlist made up of all its contents</li><li>Listen to them in an <span class="bold">ordered sequence</span> (eg. poems, by chapter, etc.) by choosing the options in the Streaming Engine</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><span class="bold"><br></span><em>Add </em><em><span class="bold">MUSIC</span> </em><em>and </em><em><span class="bold">IMAGES</span></em><em> too, for an even more captivating experience!</em></p>',
    MyArtGallery: 'My art gallery',
    MyArtGalleryDesc: '<p class="mb-short">Create VISUAL ITINERARIES for publishing or teaching as well as for pleasure:<br><span class="bold">each Epikey comprises a step</span> in your itinerary.</p><ul class="mb-short"><li>Design <span class="bold">thematic</span> and <span class="bold">chronological</span> itineraries by <span class="bold">genre, artists</span> or <span class="bold">works</span>, structured to your tastes: add or eliminate steps/Epikeys</li><li>Link images to each Epikey/step</li><li>Click on the Map “My Art Gallery” and <span class="bold">view the playlists</span> made up of all <span class="bold">related images</span></li><li>You can add <span class="bold">headings, quotes and comments </span>to describe the steps/Epikeys in your itinerary or each work of art independently</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><span class="bold"><br></span><em>Make more of an impact by adding a </em><em><span class="bold">soundtrack</span> </em><em>to accompany each step of the itinerary!</em></p>',
    TravelLog: 'Travel log',
    TravelLogDesc: '<p class="mb-short"><span class="bold">COLLECT, </span>ORGANIZE and REDISCOVER your holiday photos<br>so you can relive and share the <span class="bold">good times and sensations</span>.</p><ul class="mb-short"><li>Get a complete and ordered overview of your collection: <span class="bold">quickly access</span> and enjoy the photos you want</li><li>Design your Map according to what suits you best, subdivided into <span class="bold">continents / geographical areas / countries / places and towns, events</span>, etc.</li><li>Activate the Epikeys of a trip or groups of them: enjoy casually ordered <span class="bold">playlists</span> made up of all the <span class="bold">related photos</span>I\'ll be able to enjoy <span class="bold">experiences</span> that are <span class="bold">always different</span>: every playlist generated queues the images viewed most recently to the back</li><li>You can jot down <span class="bold">headings and notes</span> from your trips in the description field of every Epikey and each photo</li><li>When you share your Map in part or in its entirety, you can make use of the <span class="bold">comments</span> tab to relate and describe your trips to your friends</li></ul><p class="mb-regular"><em><span class="bold">Make it MULTIMEDIA!</span></em><span class="bold"><br></span><em>Add a </em><em><span class="bold">soundtrack</span> </em><em>to each Epikey to create the right mood:</em><br><em>make your trips more breathtaking and exciting!</em></p>',
    MyPeople: 'My people',
    MyPeopleDesc: '<p class="mb-short">A Map that lets you connect and keep in touch with the PEOPLE who are <span class="bold">part of your life</span>.</p><ul class="mb-regular"><li>Design and create Epikeys as if they were the <span class="bold">doors to your soul</span>, and <span class="bold">connect with those dear to you</span>, with the feelings you have for them, as well as with the experiences, places, times and adventures you\'ve shared together</li><li>Connect <span class="bold">photos, music and audio</span> to each Epikey and... open the gates to a flood of emotions: your <span class="bold">multimedia playlists</span> will bring back faces, sounds, vibes and memories.</li><li>Keep a <span class="bold">diary</span>: write down thoughts, anecdotes and unique memories in the description of every Epikey and its contents</li><li><span class="bold">Share</span> the Map or your Epikeys with whomever you like in your Sharing Spaces</li></ul>',
    MyLifesJourney: 'My life\'s journey',
    MyLifesJourneyDesc: '<p class="mb-short">REDISCOVER the adventure that you call your Life:<br>the <span class="bold">events</span>, the <span class="bold">changes</span>, the <span class="bold">places</span> and <span class="bold">encounters</span>...</p><ul class="mb-regular"><li>Re-experience <span class="bold">your life</span> through a mapped itinerary that marks various <span class="bold">periods or stages</span> in it: each Epikey represents a leg of your journey, with a <span class="bold">multimedia narration</span> of the happenings, the experiences and the people that have left their mark</li><li>Connect <span class="bold">photos, music and audiofiles</span> associated with a period in your life to each Epikey</li><li>Create your <span class="bold">autobiography</span> by writing anecdotes, thoughts, and memoirs in the description of every Epikey and its contents</li><li>Begin your journey and relieve your great adventure in the most captivating way possible with <span class="bold">multimedia playlists</span></li><li><span class="bold">Share</span> the Map or your Epikeys with whoever you want in your Sharing Spaces</li></ul>',
    MyFeelings: 'My feelings',
    MyFeelingsDesc: '<p class="mb-short">YOUR never-ending SOURCE of <span class="bold">emotional energy!</span></p><ul class="mb-regular"><li>Use the enchanting power of <span class="bold">music, sounds and images</span> to conjure  up positive feelings, helpful frames of mind, and to light up your inspiration</li><li>Design your Map as a special <span class="bold">“access menu” to your emotions</span>: each Epikey becomes a menu item, to which you can link all multimedia content that you associate with a particular feeling</li><li>Activate the Epikeys and bathe yourself in the empowering <span class="bold">(soothing)</span> flow of your <span class="bold">uniquely customized emotions playlists</span></li><li>Enjoy <span class="bold">experiences</span> that are <span class="bold">always different</span>, generated randomly while the most recently played content gets queued to the end</li><li><span class="bold">Share</span> the Map or your Epikeys with whoever you like in your Sharing Spaces</li></ul>',
    // DIGITAL CHAOS
    DigitalChaosTitle1: 'OVERCOME DIGITAL CHAOS',
    DigitalChaosSubText1: 'We are swamped by digital content',
    DigitalChaosSubText2: 'But will we ever be able to use it efectively in our lives?',
    DigitalChaosSubTitle1: 'Digital content is precious to us',
    DigitalChaosText1: 'Just think about the impact that music and images can have on your emotional state or how your photos/videos can carry you back into your memories, or just consider what you can learn from audio lessons, or Pdf educational contents etc.<br>Digital content is becoming <span class="bold">increasingly available and accessible</span> from a massive diversity of public and private sources.<br>This means we have the opportunity to benefit from them in our lives and use them for our personal development.',
    DigitalChaosSubTitle2: 'The Digital Chaos',
    DigitalChaosText2: 'Nowadays <span class="bold">we are continuously swamped by digital content</span>.<br><br>Let’s consider for instance:<br>• The content that we download from the web<br>• The content thrown at us in the form of advertising and suggestions,<br>• The content in the text messages we receive from our contacts,<br>• The material we receive from groups, channels, sites, mailing lists we subscribed to<br>• The content we create ourselves, such as the massive number of photos and videos we create every day, or audio or text notes that we take<br>• And there is more... ',
    DigitalChaosText3: '<span class="bold">Despite the overabundance and availability</span> of all this digital content we end up reading or listening to it too quickly and carelessly, or merely glimpsing at it (often "on the go") without giving ourselves the time to enjoy it, truly appreciate it or think it over.<br>Nor do we take the time to make summaries, notes, and organize the content in such a way as to make the most out of it. In the end <span class="bold">we are not able to practically enhance or integrate it into our daily lives</span>.<br><br> Hence <span class="bold">the greatest challenge</span> facing humanity nowadays has shifted from the accessibility problems to that of <span class="bold">content management</span>.<br><br>This problem could arguably be categorized as follows:<br><span class="bold">1- Filter/Select content</span> What content do I really need? <br><span class="bold">2- Manage content</span> Where do I place it? How do I use it? What do I need it for? How could all this content impact my life? And so on…',
    DigitalChaosSubTitle3: 'The Epicentric solution',
    DigitalChaosText4: 'Epicentric has been designed to overcome digital chaos and allow you to <span class="bold">get the most out of the digital content in your life</span>.<br><br><span class="bold">Your Epicentric is</span><br><span class="bold">- A one-and-only place</span> in which you can gather all types of digital content, in an unlimited fashion.<br><a href="/your-sacred-space" class="link">Discover your sacred space.</a><br><br><span class="bold">- A powerful instrument</span> to enhance, organize, personalize, enjoy and share your content in a natural, intuitive way.<br><a href="/revolutionary-tool" class="link">Discover a revolutionary tool</a><br><br><span class="bold">- An empowering tool</span> to consciously integrate knowledge along your path of Self-discovery and creation.',
    // Your Sacred space
    SacredSpaceTitle1: 'YOUR SACRED SPACE',
    SacredSpaceSubText1: 'Set up your Digital Home',
    SacredSpaceSubText2: 'A one and only private place for all your digital content',
    SacredSpaceSubTitle1: 'Stop the digital invasion',
    SacredSpaceText1: 'In the "physical world" we have a home, a shelter, a safe and comfortable place to keep the things we love, whereas in the digital world we are still unable to carve out a sacred <span class="bold">space for ourselves</span> and drawing a line between inside and outside.<br>Think about it for a moment:<br><i>Would you allow someone into your home to fill it up with items?<br>To stuff books into your library?<br>To organize your photos and make an album?<br>Suddenly pop a movie up on your Tv screen?</i><br>Probably not.',
    SacredSpaceText2: 'In our digital world we are continuously invaded by content, and our digital dimension is becoming more and more similar to Winston’s apartment, where the protagonist of Orwell’s 1984 cannot turn off the TV!',
    SacredSpaceSubTitle2: 'Your Digital Home',
    SacredSpaceText3: 'Similarly to what happens in the physical world, even in the digital one you can have your own home, experience your private dimension, and set up your own space to <span class="bold">keep and care for the things you love</span>.',
    SacredSpaceSmallTitle1: 'Your space is Sacred',
    SacredSpaceText4: 'No advertisement.<br>No suggested content.<br>No interference of any kind.<br>No content can enter your visual/auditory space if you don\'t want it to.',
    SacredSpaceSmallTitle2: 'Your space is Private',
    SacredSpaceText5: 'Your profile, your Maps and your content are not located in a public space, nor are they tracked by any search engine.',
    SacredSpaceSmallTitle3: 'Your space is free from surveillance',
    SacredSpaceText6: 'We will not share your data with any third parties.<br>We do not engage in any surveillance capitalism whatsoever.',
    SacredSpaceSubTitle3: 'A One and Only Place',
    SacredSpaceSubText3: 'The content you love at your fingertips',
    SacredSpaceText7: '<span class="bold">The problems of scattered content</span><br><br>Let\'s take a guess:<br><i>Do you have many photos and videos on Instagram,<br>and more on your mobile?<br>Do you stream movies, <br>but you have some titles on one or more hard drives?<br>Do you stream music, <br>but some tracks or audio recordings are on your desktop?</i><br><br>It is a very common situation.',
    SacredSpaceText8: '<span class="bold">Digital content</span> that is now part of our lives is <span class="bold">scattered just about everywhere</span>: desktop and mobile devices, cloud spaces, external hard drives, various platforms, CDs/DVDs and so on…<br>Therefore, searching for this content and accessing it is not always easy and immediate.<br><br><span class="bold">Epicentric solves this problem.</span>',
    SacredSpaceSubTitle4: 'Your personal Media Hub',
    SacredSpaceSmallTitle4: 'Epicentric is multimedia',
    SacredSpaceText9: 'It has been designed to comprehensively manage all different kinds of content at once (images, Audios, Videos, Pdf’s etc.)',
    SacredSpaceSmallTitle5: 'It is an open system',
    SacredSpaceText10: 'You can use it in two different ways:<br><br><span class="bold">1. Public domain content aggregator</span><br>• Embed videos from the most common platforms (i.e Youtube, Vimeo, Rumble etc.)<br>• Download files from the web<br>• Add bookmarks to any web address<br><br><span class="bold">2. Cloud storage service for you owned content</span><br>• Manually upload files from your devices <br>• Authorize Epicentric to automatically take content from specific folders that you choose on your devices (Coming soon)<br><br>All the content coming from the different sources mentioned above, flows into your one and only media catalog which is organized in 4 sections:',
    SacredSpaceSmallTitle6: 'It is easy to access',
    SacredSpaceText11: 'You do not have to install any software.<br>You can access Epicentric from any device with a browser.',
    SacredSpaceSubTitle5: 'Your catalog without limits',
    SacredSpaceText12: 'In recent years, we have witnessed the growth and establishment, especially for Music and Videos, of <span class="bold">the "rental" model</span>:<br>today most people use monthly "all you can eat" subscriptions to access the catalogs of Audio (Audible etc.) Music (Spotify/Youtube etc.) and Visual (Netflix etc.) <span class="bold">streaming platforms</span>.<br><br>Nevertheless, this model does have some <span class="bold">important limitations</span>:<br><br>There is a lot a content that <span class="bold">you cannot find on any platform</span><br><br>Some content is <span class="bold">exclusive to certain platforms</span>, forcing you to make multiple subscriptions.<br><br>More and more content is being is <span class="bold">restricted or censored</span> for political reasons.<br><br>You cannot upload <span class="bold">the content you create</span> (videos, voice notes, recordings etc.) on those platforms, and in any case, you won’t be able to consistently and comprehensively manage it.<br><br>This could be <span class="bold">frustrating</span> and produces <span class="bold">further dispersion</span>.<br><br><span class="bold">In Epicentric</span>, instead, no one can limit your choices: <span class="bold">you are in your own digital home</span>. <br><br>You can bring together all the content you want in Epicentric, and not only that: <br>you can <span class="bold">enhance it, organize it,</span> and <span class="bold">enjoy it to the fullest</span>, by using a powerful multimedia station<br><br><a href="/revolutionary-tool" class="link">Discover a revolutionary tool</a>',
    // Epicentric Vs
    EpicentricVsTitle: "Epicentric Vs",
    EpicentricVsSubTitle1: "Engineered to be different",
    EpicentricVsSubTitle2: "Totally another kind of streaming",
  }
}