import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // epicentric-technology
  {
    path: '/',
    name: 'epicentric-technology-home',
    component: () => import(/* webpackChunkName: "epicentric-technology" */ '../views/epicentric-technology/epicentric-technology.vue'),
    meta: {
      seoTitle: 'Epicentric technology',
      seoDescription: ''
    }
  },
  {
    path: '/epicentric-technology',
    name: 'epicentric-technology',
    component: () => import(/* webpackChunkName: "epicentric-technology" */ '../views/epicentric-technology/epicentric-technology.vue'),
    meta: {
      seoTitle: 'Fundamentals',
      seoDescription: ''
    }
  },
  {
    path: '/epicentric-technology/your-catalog',
    name: 'your-catalog',
    component: () => import(/* webpackChunkName: "epicentric-technology" */ '../views/epicentric-technology/your-catalog.vue'),
    meta: {
      seoTitle: 'Your catalog',
      seoDescription: ''
    }
  },
  {
    path: '/epicentric-technology/epikeys',
    name: 'epikeys',
    component: () => import(/* webpackChunkName: "epicentric-technology" */ '../views/epicentric-technology/epikeys.vue'),
    meta: {
      seoTitle: 'Epikeys',
      seoDescription: ''
    }
  },
  {
    path: '/epicentric-technology/links',
    name: 'links',
    component: () => import(/* webpackChunkName: "epicentric-technology" */ '../views/epicentric-technology/links.vue'),
    meta: {
      seoTitle: 'Links',
      seoDescription: ''
    }
  },
  {
    path: '/epicentric-technology/maps',
    name: 'maps',
    component: () => import(/* webpackChunkName: "epicentric-technology" */ '../views/epicentric-technology/maps.vue'),
    meta: {
      seoTitle: 'Maps',
      seoDescription: ''
    }
  },
  {
    path: '/epicentric-technology/your-digital-universe',
    name: 'your-digital-universe',
    component: () => import(/* webpackChunkName: "epicentric-technology" */ '../views/epicentric-technology/your-digital-universe.vue'),
    meta: {
      seoTitle: 'Your digital universe',
      seoDescription: ''
    }
  },
  // access-and-play-your-content
  {
    path: '/access-and-play-your-content',
    name: 'access-and-play-your-content',
    component: () => import(/* webpackChunkName: "access-and-play-your-content" */ '../views/access-and-play-your-content/access-and-play-your-content.vue'),
    meta: {
      seoTitle: 'Access and play your content',
      seoDescription: ''
    }
  },
  {
    path: '/access-and-play-your-content/from-your-catalog',
    name: 'from-your-catalog',
    component: () => import(/* webpackChunkName: "access-and-play-your-content" */ '../views/access-and-play-your-content/from-your-catalog.vue'),
    meta: {
      seoTitle: 'From your catalog',
      seoDescription: ''
    }
  },
  {
    path: '/access-and-play-your-content/through-your-links',
    name: 'through-your-links',
    component: () => import(/* webpackChunkName: "access-and-play-your-content" */ '../views/access-and-play-your-content/through-your-links.vue'),
    meta: {
      seoTitle: 'Through your links',
      seoDescription: ''
    }
  },
  {
    path: '/access-and-play-your-content/generating-playlists',
    name: 'generating-playlists',
    component: () => import(/* webpackChunkName: "access-and-play-your-content" */ '../views/access-and-play-your-content/generating-playlists.vue'),
    meta: {
      seoTitle: 'Generating playlists',
      seoDescription: ''
    }
  },
  // Playlist engine
  {
    path: '/playlist-engine',
    name: 'playlist-engine',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/playlist-engine.vue'),
    meta: {
      seoTitle: 'Playlist engine',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/intelligent-random',
    name: 'intelligent-random',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/intelligent-random.vue'),
    meta: {
      seoTitle: 'Intelligent random',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/single-playlist-mode',
    name: 'single-playlist-mode',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/single-playlist-mode.vue'),
    meta: {
      seoTitle: 'Single playlist mode',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/full-random',
    name: 'full-random',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/full-random.vue'),
    meta: {
      seoTitle: 'Full random',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/one-epikey-at-time',
    name: 'one-epikey-at-time',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/one-epikey-at-time.vue'),
    meta: {
      seoTitle: 'One Epikey at time',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/playlist-path-mode',
    name: 'playlist-path-mode',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/playlist-path-mode.vue'),
    meta: {
      seoTitle: 'Playlist path mode',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/playlist-path-mode/loop-manual-skip',
    name: 'loop-manual-skip',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/loop-manual-skip.vue'),
    meta: {
      seoTitle: 'Loop - manual skip',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/playlist-path-mode/loop-timed-skip',
    name: 'loop-timed-skip',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/loop-timed-skip.vue'),
    meta: {
      seoTitle: 'Loop - timed skip',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/playlist-path-mode/audio-driven-skip',
    name: 'audio-driven-skip',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/audio-driven-skip.vue'),
    meta: {
      seoTitle: 'Audio - driven skip',
      seoDescription: ''
    }
  },
  {
    path: '/playlist-engine/ignore-ordered-sequences',
    name: 'ignore-ordered-sequences',
    component: () => import(/* webpackChunkName: "playlist-engine" */ '../views/playlist-engine/ignore-ordered-sequences.vue'),
    meta: {
      seoTitle: 'Ignore ordered sequences',
      seoDescription: ''
    }
  },
  // The Multilevel Customization
  {
    path: '/the-multilevel-customization',
    name: 'the-multilevel-customization',
    component: () => import(/* webpackChunkName: "the-multilevel-customization" */ '../views/the-multilevel-customization/the-multilevel-customization.vue'),
    meta: {
      seoTitle: 'The multilevel customization',
      seoDescription: ''
    }
  },
  {
    path: '/the-multilevel-customization/customize-at-content-level',
    name: 'customize-at-content-level',
    component: () => import(/* webpackChunkName: "the-multilevel-customization" */ '../views/the-multilevel-customization/customize-at-content-level.vue'),
    meta: {
      seoTitle: 'Customize at content level',
      seoDescription: ''
    }
  },
  {
    path: '/the-multilevel-customization/customize-at-link-level',
    name: 'customize-at-link-level',
    component: () => import(/* webpackChunkName: "the-multilevel-customization" */ '../views/the-multilevel-customization/customize-at-link-level.vue'),
    meta: {
      seoTitle: 'Customize at Link level',
      seoDescription: ''
    }
  },
  {
    path: '/the-multilevel-customization/customize-at-epikey-level',
    name: 'customize-at-epikey-level',
    component: () => import(/* webpackChunkName: "the-multilevel-customization" */ '../views/the-multilevel-customization/customize-at-epikey-level.vue'),
    meta: {
      seoTitle: 'Customize at Epikey level',
      seoDescription: ''
    }
  },
  {
    path: '/the-multilevel-customization/conflicts-between-parameters',
    name: 'conflicts-between-parameters',
    component: () => import(/* webpackChunkName: "the-multilevel-customization" */ '../views/the-multilevel-customization/conflicts-between-parameters.vue'),
    meta: {
      seoTitle: 'Conflicts between parameters',
      seoDescription: ''
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to) => {
  if (to.meta.seoTitle !== '') {
    document.title = 'Epicentric® - ' + to.meta.seoTitle
  }
  if (to.meta.seoDescription !== '') {
    document.getElementsByTagName('meta')['description'].content = to.meta.seoDescription
  }
})


export default router
